import React from "react";

const Testimonials = () => {
  return (
    <section
      id="testimonials"
      className="flex flex-col items-center justify-center py-28"
    >
      <div className="flex flex-col w-full border-opacity-50 py-14">
        <div className="divider">
          <h1 className="text-4xl md:text-5xl font-bold">Depoimentos</h1>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-10">
        {/* Depoimento 1 */}
        <div className="max-w-md mx-auto scale-95 hover:scale-100 transition-all duration-300">
          <div className="bg-base-200 border border-gray-200 shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-4">
              <div className="avatar">
                <div className="w-16 mr-4 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src="/images/user.png" alt="foto do usuário" />
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold">John Doe</h4>
                <p className="text-gray-600">CEO, Photo Archive</p>
              </div>
            </div>
            <p className="text-base-content mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              venenatis eleifend nisi, et hendrerit purus varius at. Integer non
              risus in lectus blandit fringilla eu nec risus.
            </p>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <span className="text-gray-600 ml-2">5.0</span>
            </div>
          </div>
        </div>
        {/* Depoimento 2 */}
        <div className="max-w-md mx-auto scale-95 hover:scale-100 transition-all duration-300">
          <div className="bg-base-200 border border-gray-200 shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-4">
              <div className="avatar">
                <div className="w-16 mr-4 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src="/images/user.png" alt="foto do usuário" />
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold">Petter Doe</h4>
                <p className="text-gray-600">Desenvolvedor, Photo Archive</p>
              </div>
            </div>
            <p className="text-base-content mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              venenatis eleifend nisi, et hendrerit purus varius at. Integer non
              risus in lectus blandit fringilla eu nec risus.
            </p>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <span className="text-gray-600 ml-2">3.75</span>
            </div>
          </div>
        </div>
        {/* Depoimento 3 */}
        <div className="max-w-md mx-auto scale-95 hover:scale-100 transition-all duration-300">
          <div className="bg-base-200 border border-gray-200 shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-4">
              <div className="avatar">
                <div className="w-16 mr-4 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src="/images/user.png" alt="foto do usuário" />
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold">Leon Doe</h4>
                <p className="text-gray-600">Desenvolvedor, Photo Archive</p>
              </div>
            </div>
            <p className="text-base-content mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              venenatis eleifend nisi, et hendrerit purus varius at. Integer non
              risus in lectus blandit fringilla eu nec risus.
            </p>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <span className="text-gray-600 ml-2">4.5</span>
            </div>
          </div>
        </div>
        {/* Depoimento 4 */}
        <div className="max-w-md mx-auto scale-95 hover:scale-100 transition-all duration-300">
          <div className="bg-base-200 border border-gray-200 shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-4">
              <div className="avatar">
                <div className="w-16 mr-4 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src="/images/user.png" alt="foto do usuário" />
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold">Feed Doe</h4>
                <p className="text-gray-600">CEO, Photo Archive</p>
              </div>
            </div>
            <p className="text-base-content mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              venenatis eleifend nisi, et hendrerit purus varius at. Integer non
              risus in lectus blandit fringilla eu nec risus.
            </p>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <span className="text-gray-600 ml-2">5.0</span>
            </div>
          </div>
        </div>
        {/* Depoimento 5 */}
        <div className="max-w-md mx-auto scale-95 hover:scale-100 transition-all duration-300">
          <div className="bg-base-200 border border-gray-200 shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-4">
              <div className="avatar">
                <div className="w-16 mr-4 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                  <img src="/images/user.png" alt="foto do usuário" />
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold">Rex Doe</h4>
                <p className="text-gray-600">Coo-Fundados, Photo Archive</p>
              </div>
            </div>
            <p className="text-base-content mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              venenatis eleifend nisi, et hendrerit purus varius at. Integer non
              risus in lectus blandit fringilla eu nec risus.
            </p>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 text-yellow-500 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <span className="text-gray-600 ml-2">5.0</span>
            </div>
          </div>
        </div>
        {/* End testimonials */}
      </div>
    </section>
  );
};

export default Testimonials;
