/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from "./Modal";
import { Link } from "react-scroll";

const Navbar = () => {
  return (
    <>
      <div className="p-4 bg-base-200 fixed w-full top-0 left-0 right-0 z-50">
        <div className="w-full flex flex-col sm:flex-row items-center justify-center sm:justify-between">
          <Link
            to="page"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="cursor-pointer"
          >
            <img src="/images/logo.png" alt="logo" className="w-48 invert" />
          </Link>
          <ul className="menu menu-horizontal px-1">
            <li>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="cursor-pointer"
              >
                <i className="bi bi-info-circle" /> Sobre
              </Link>
            </li>
            <li>
              <Link
                to="pricing"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="cursor-pointer"
              >
                <i className="bi bi-coin" />
                Preços
              </Link>
            </li>
            <li>
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="cursor-pointer"
              >
                <i className="bi bi-people" />
                Depoimentos
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <i className="bi bi-envelope" /> Contato
              </Link>
            </li>
            <li>
              <a onClick={() => window.loginModal.showModal()}>
                <i className="bi bi-person-circle" /> Login
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Modal id="loginModal" title={"Login"}>
        <div className="mb-4 mt-2">
          <label
            className="block text-neutral-content text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="appearance-none bg-base-200 border rounded w-full py-2 px-3 text-base-content leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-neutral-content text-sm font-bold mb-2"
            htmlFor="password"
          >
            Senha
          </label>
          <input
            className="appearance-none bg-base-200 border rounded w-full py-2 px-3 text-base-content leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Senha"
          />
        </div>
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input type="checkbox" className="form-checkbox" />
            <span className="ml-2">Lembrar-me</span>
          </label>
        </div>
        <div className="flex items-center justify-between">
          <button className="btn btn-neutral my-6">Entrar</button>
          <a className="cursor-pointer inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
            Crie uma conta
          </a>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
