import React from "react";

const Pricing = () => {
  return (
    <section id="pricing" className="flex flex-col items-center justify-center">
      <div className="flex flex-col w-full border-opacity-50 py-28">
        <div className="divider">
          <h1 className="text-4xl md:text-5xl font-bold">Planos e Preços</h1>
        </div>
      </div>
      <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center">
        {/* Bronze */}
        <div className="w-full max-w-sm p-4 bg-base-200 border border-gray-200 rounded-lg shadow sm:p-8 scale-90 hover:scale-100 transition-all duration-300">
          <h5 className="mb-4 text-xl font-medium text-gray-500">Bronze</h5>
          <div className="text-base-content">
            <span className="text-3xl font-semibold ">R$</span>
            <span className="text-4xl md:text-5xl font-extrabold tracking-tight">
              10
            </span>
            <span className="ml-1 text-xl font-normal text-gray-500">/mês</span>
          </div>
          <ul className="space-y-5 my-7">
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                Acesso em qualquer dispositivo
              </span>
            </li>
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                1GB de Armazenamento
              </span>
            </li>
            <li className="flex space-x-3 line-through decoration-gray-500">
              <i className="bi bi-check text-gray-400" />
              <span className="text-base font-normal leading-tight text-gray-500">
                Suporte via WhatsApp
              </span>
            </li>
          </ul>
          <div className="flex justify-center">
            <button className="btn btn-neutral">Quero este</button>
          </div>
        </div>
        {/* Ouro */}
        <div className="w-full max-w-sm p-4 bg-base-300 border border-gray-200 rounded-lg shadow sm:p-8 scale-100 hover:scale-110 transition-all duration-300">
          <h5 className="mb-4 text-xl font-medium text-gray-500">Ouro</h5>
          <div className="text-base-content">
            <span className="text-4xl md:text-5xl font-semibold">R$</span>
            <span className="text-5xl font-extrabold tracking-tight">30</span>
            <span className="ml-1 text-xl font-normal text-gray-500">/mês</span>
          </div>
          <ul className="space-y-5 my-7">
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                Acesso em qualquer dispositivo
              </span>
            </li>
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                10GB de Armazenamento
              </span>
            </li>
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                Suporte via WhatsApp
              </span>
            </li>
          </ul>
          <div className="flex justify-center">
            <button className="btn btn-neutral">Quero este</button>
          </div>
        </div>
        {/* Prata */}
        <div className="w-full max-w-sm p-4 bg-base-200 border border-gray-200 rounded-lg shadow sm:p-8 scale-90 hover:scale-100 transition-all duration-300">
          <h5 className="mb-4 text-xl font-medium text-gray-500">Prata</h5>
          <div className="text-base-content">
            <span className="text-4xl md:text-5xl font-semibold">R$</span>
            <span className="text-5xl font-extrabold tracking-tight">20</span>
            <span className="ml-1 text-xl font-normal text-gray-500">/mês</span>
          </div>
          <ul className="space-y-5 my-7">
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                Acesso em qualquer dispositivo
              </span>
            </li>
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                5GB de Armazenamento
              </span>
            </li>
            <li className="flex space-x-3">
              <i className="bi bi-check text-green-600 text-2xl" />
              <span className="text-base font-normal leading-tight text-gray-500">
                Suporte via WhatsApp
              </span>
            </li>
          </ul>
          <div className="flex justify-center">
            <button className="btn btn-neutral">Quero este</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
