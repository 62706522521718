/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => {
  return (
    <footer className="footer footer-center p-10 bg-base-200 text-base-content">
      <div>
        <div className="grid grid-flow-col gap-4">
          <a href="#" className="link link-hover">
            Sobre
          </a>
          <a href="#" className="link link-hover">
            Contato
          </a>
          <a href="#" className="link link-hover">
            Política de Privacidade
          </a>
          <a href="#" className="link link-hover">
            Termos de Uso
          </a>
        </div>
      </div>
      <div>
        <div className="grid grid-flow-col gap-4">
          <div className="border border-base-content rounded-full px-2 py-1 hover:text-blue-600 transition-all duration-300">
            <a href="#" className="cursor-pointer">
              <i className="bi bi-facebook text-2xl" />
            </a>
          </div>
          <div className="border border-base-content rounded-full px-2 py-1 hover:text-pink-600 transition-all duration-300">
            <a href="#" className="cursor-pointer">
              <i className="bi bi-instagram text-2xl" />
            </a>
          </div>
          <div className="border border-base-content rounded-full px-2 py-1 hover:text-sky-500 transition-all duration-300">
            <a href="#" className="cursor-pointer">
              <i className="bi bi-twitter text-2xl" />
            </a>
          </div>
        </div>
      </div>
      <div>
        <p>Copyright © 2023 Photo Archive - All right reserved</p>
        <p className="pt-4">
          Desenvolvido com ❤️ por{" "}
          <a target="_blank" rel="noreferrer" href="https://jprzimba.com.br">
            <span className="font-teko text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-yellow-500 to-orange-500 shadow-fire">
              jprzimba
            </span>
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
