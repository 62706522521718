const Modal = ({ id, title, children }) => {
  if (!id) return null;

  return (
    <>
      <dialog id={id} className="modal">
        <form method="dialog" className="modal-box">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
            ✕
          </button>
          <h3 className="font-bold text-lg">{title}</h3>
          {children}
        </form>
      </dialog>
    </>
  );
};
export default Modal;
