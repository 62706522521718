/* eslint-disable jsx-a11y/anchor-is-valid */
import Modal from "./Modal";

const About = () => {
  return (
    <>
      <section id="about" className="hero min-h-screen bg-base-200 pt-40">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-4xl md:text-5xl font-bold my-6">
              Photo Archive
            </h1>
            <p className="text-lg">
              O Photo Archive é um sistema de gerenciamento de arquivos que
              permite que você organize e gerencie as fotos de seus clientes de
              forma eficiente.
            </p>
            <br />
            <p className="text-lg">
              Com o Photo Archive, você pode fazer o upload, categorizar e
              pesquisar facilmente por fotos, tornando a organização e o acesso
              rápido uma tarefa simples.
            </p>
            <br />
            <p className="text-lg">
              Faça login ou cadastre-se para começar a gerenciar seus arquivos e
              aprimorar sua experiência no gerenciamento de fotos.
            </p>
            <button
              className="btn btn-neutral my-6"
              onClick={() => window.aboutLoginModal.showModal()}
            >
              Cadastre-se
            </button>
          </div>
        </div>
      </section>

      <Modal id="aboutLoginModal" title="Cadastre-se">
        <div className="mb-4">
          <label
            className="block text-neutral-content text-sm font-bold my-2"
            htmlFor="fullName"
          >
            Nome completo
          </label>
          <input
            className="appearance-none bg-base-200 border rounded w-full py-2 px-3 text-neutral-content leading-tight focus:outline-none focus:shadow-outline"
            id="fullName"
            type="text"
            placeholder="Nome completo"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-neutral-content text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="appearance-none bg-base-200 border rounded w-full py-2 px-3 text-neutral-content leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-neutral-content text-sm font-bold mb-2"
            htmlFor="password"
          >
            Senha
          </label>
          <input
            className="appearance-none bg-base-200 border rounded w-full py-2 px-3 text-neutral-content leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Senha"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-neutral-content text-sm font-bold mb-2"
            htmlFor="confirmPassword"
          >
            Confirmar senha
          </label>
          <input
            className="appearance-none bg-base-200 border rounded w-full py-2 px-3 text-neutral-content leading-tight focus:outline-none focus:shadow-outline"
            id="confirmPassword"
            type="password"
            placeholder="Confirmar senha"
          />
        </div>
        <div className="flex items-center justify-between">
          <button className="btn btn-neutral my-6">Cadastrar</button>
          <a
            className="cursor-pointer inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            onClick={() => window.aboutLoginModal.close()}
          >
            Já tem uma conta? Login
          </a>
        </div>
      </Modal>
    </>
  );
};

export default About;
