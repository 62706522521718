import React from "react";

const Contact = () => {
  return (
    <>
      {/* Contact Form */}
      <section id="contact" className="p-4 mx-auto md:px-6">
        <div className="flex flex-col w-full border-opacity-50 pb-28">
          <div className="divider">
            <h1 className="text-4xl md:text-5xl font-bold">Contato</h1>
          </div>
        </div>
        {/* Section: Design Block */}
        <div className="mb-32">
          <div className="flex flex-wrap">
            <div className="mb-10 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
              <h2 className="mb-6 text-3xl font-bold">Fale conosco</h2>
              <p className="mb-6 text-neutral-500">
                Informações de contato, tais como endereço, e-mail e telefone.
              </p>
              <p className="mb-2 text-neutral-500">
                Imbituba, 94126, Santa Catarina - Brasil
              </p>
              <p className="mb-2 text-neutral-500">+55 (48) 9999 9999</p>
              <p className="mb-2 text-neutral-500">info@exemplo.com</p>
            </div>
            <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:px-6">
              <form>
                <div className="relative mb-6">
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-l border-b border-base-content bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-0 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-0 motion-reduce:transition-none"
                    id="contact_name"
                    placeholder="Nome"
                  />
                  <label
                    className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-400 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                    htmlFor="contact_name"
                  >
                    Nome
                  </label>
                </div>
                <div className="relative mb-6">
                  <input
                    type="email"
                    className="peer block min-h-[auto] w-full rounded border-l border-b border-base-content bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-0 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-0 motion-reduce:transition-none"
                    id="contact_email"
                    placeholder="Email"
                  />
                  <label
                    className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                    htmlFor="contact_email"
                  >
                    E-mail
                  </label>
                </div>
                <div className="relative mb-6">
                  <input
                    type="text"
                    className="peer block min-h-[auto] w-full rounded border-l border-b border-base-content bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-0 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-0 motion-reduce:transition-none"
                    id="contact_phone"
                    placeholder="Telefone"
                  />
                  <label
                    className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                    htmlFor="contact_phone"
                  >
                    Telefone
                  </label>
                </div>
                <div className="relative mb-6">
                  <textarea
                    className="peer block min-h-[auto] resize-none w-full rounded border-l border-b border-base-content bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-0 data-[te-input-state-active]:placeholder:opacity-0 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                    id="contact_message"
                    rows={3}
                    placeholder="Digite sua mensagem"
                    defaultValue={""}
                  />
                  <label
                    htmlFor="contact_message"
                    className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                  >
                    Mensagem
                  </label>
                </div>
                <button className="btn btn-neutral">Enviar mensagem</button>
              </form>
            </div>
          </div>
        </div>
        {/* Section: Design Block */}
      </section>
    </>
  );
};

export default Contact;
